import {StylesSxT, StylesT} from "~utils/utils";

const styles: StylesT = {
    wrapperList: {
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        marginLeft: -25,
        marginTop: 30,
    },
    textField: {
        width: "100%",
    },
    iconButtonTokenRefresh: {
        marginLeft: 5,
    },
    iconButtonTopicRefresh: {
        marginLeft: -5,
    },
    topicsHeadingLabel: {
        marginLeft: 10,
    },
};

const stylesSX: StylesSxT = {
    holderModalBody: {
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        padding: 2,
        borderWidth: 0,
    },
};

export {styles, stylesSX};
