import {StylesSxT, StylesT} from "~utils/utils";

const styles: StylesT = {};

const stylesSX: StylesSxT = {
    boxWrapper: {
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 400,
    },
    tabs: {
        borderRight: 1,
        borderColor: "divider",
    },
};

export {stylesSX, styles};
