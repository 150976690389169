import {createSlice, Dispatch} from "@reduxjs/toolkit";
import {Slices} from "~redux/reducers/interface";
import {ThemeState} from "~theme/interface";

const initialState: ThemeState = {
    darkMode: !!JSON.parse(localStorage.getItem("darkMode") as string),
};

export const themeSlice = createSlice({
    name: Slices.theme,
    initialState,
    reducers: {
        toggleTheme: (state: ThemeState) => {
            state.darkMode = !state.darkMode;
        },
    },
});

export const asyncToggleTheme = () => (dispatch: Dispatch) => {
    const isDarkMode: boolean = !!JSON.parse(localStorage.getItem("darkMode") as string);
    localStorage.setItem("darkMode", `${!isDarkMode}`);
    dispatch(themeActions.toggleTheme());
};

export const themeActions = themeSlice.actions;
const themeReducer = themeSlice.reducer;
export default themeReducer;
