import React from "react";
import {Provider} from "react-redux";

import {configureStore, Store} from "@reduxjs/toolkit";
import createSagaMiddleware, {SagaMiddleware} from "redux-saga";
import rootReducer from "~redux/reducers/reducers";
import rootSaga from "~redux/sagas/sagas";

export type RootState = ReturnType<typeof rootReducer>;

export default function createStore(): Store {
    const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
    const middlewares: any[] = [sagaMiddleware];

    const clientStore = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    });

    sagaMiddleware.run(rootSaga);
    return clientStore;
}

export const store = createStore();
export const reduxStore = store;

export function ReduxProvider({children}: {children: React.ReactElement}) {
    return <Provider store={store}>{children}</Provider>;
}
