const styles = {
    boxSXStyles: {
        width: "100%",
        backgroundColor: "silver",
        borderRadius: 2,
    },
    boxStyle: {
        padding: 12,
    },
};

export default styles;
