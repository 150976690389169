import {Box, Button, Tab, Tabs} from "@mui/material";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {verifyUser} from "src/feature/home-test/actions";
import {UserData, VerifyUserAction} from "src/feature/home-test/interface";
import {RootState} from "~redux/reducers/interface";
import {Nullable} from "~utils/utils";
import {selectSelectedUser} from "../reducers";
import {TabsDetailsLabels} from "./interface";
import PushToken from "./push-token/PushToken";
import {stylesSX} from "./styles";
import TabPanel from "./tab-panel/TabPanel";
import TestPushNotifications from "./test-push-notifications/TestPushNotifications";
import UserDetails from "./user-details/UserDetails";

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}
interface StateProps {
    user: Nullable<UserData>;
}

interface OwnState {
    value: number;
}

interface DispatchProps {
    verifyUser(payload: string): VerifyUserAction;
}

type Props = StateProps & DispatchProps;
type State = OwnState;

class TabsWithDetails extends PureComponent<Props, State> {
    state: State = {
        value: 0,
    };

    handleChange(event: React.SyntheticEvent, newValue: number) {
        this.setState({value: newValue});
    }

    render() {
        const {user}: Props = this.props;
        const {value}: State = this.state;
        return (
            <Box sx={stylesSX.boxWrapper}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    sx={stylesSX.tabs}
                >
                    <Tab label={TabsDetailsLabels.USER_DETAILS} {...a11yProps(0)} />
                    <Tab label={TabsDetailsLabels.PUSH_NOTIFICATION_TOKEN} {...a11yProps(2)} />
                    <Tab label={TabsDetailsLabels.PUSH_NOTIFICATIONS} {...a11yProps(1)} />
                    <Tab label={TabsDetailsLabels.VERIFY_USER} {...a11yProps(3)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <UserDetails user={user} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PushToken token={user?.pushToken} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TestPushNotifications />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                this.props.verifyUser(user!.id);
                            }}
                        >
                            Verify user
                        </Button>
                    </div>
                </TabPanel>
            </Box>
        );
    }
}

const mapStateToProps = ({notificationTest}: RootState): StateProps => ({
    user: selectSelectedUser(notificationTest),
});

const mapDispatchToProps: DispatchProps = {
    verifyUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsWithDetails);
