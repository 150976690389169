import SendIcon from "@mui/icons-material/Send";
import {Button, Grid} from "@mui/material";
import React, {PureComponent} from "react";

interface OwnProps {
    cancelOnClick?: () => void;
    sendPushNotification?: any;
}
type Props = OwnProps;
class BottomButtonGroup extends PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.sendNotificationMethod = this.sendNotificationMethod.bind(this);
    }

    sendNotificationMethod() {
        const {sendPushNotification, cancelOnClick}: Props = this.props;

        if (sendPushNotification) sendPushNotification();
        if (cancelOnClick) cancelOnClick();
    }

    render() {
        const {cancelOnClick}: Props = this.props;
        return (
            <Grid container xs={12} style={{marginTop: 40}}>
                <Grid container xs={6}>
                    <Button style={{width: "80%"}} variant="contained" onClick={cancelOnClick}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        onClick={this.sendNotificationMethod}
                        startIcon={<SendIcon />}
                        color="success"
                        style={{width: "100%"}}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default BottomButtonGroup;
