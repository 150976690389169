import {Grid} from "@mui/material";
import {DataGrid, GridColDef, GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {UserData} from "src/feature/home-test/interface";
import {RootState} from "~redux/reducers/interface";
import {Nullable} from "~utils/utils";
import {SetSelectedUserAction} from "./interface";
import {notificationsTestActions, selectSelectedUser, selectUsers} from "./reducers";
import styles from "./styles";
import GroupButtons from "./table-actions-items/GroupButtons";
import RenderApproveIcon from "./table-actions-items/RenderApproveIcon";
import TabsWithDetails from "./tabs-with-details/TabsWithDetails";

const testColumns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        width: 70,
    },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params: GridValueGetterParams) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
        field: "hasPermissions",
        headerName: "Permissions",
        renderCell: RenderApproveIcon,
        align: "center",
    },
    {
        field: "requestUser",
        headerName: "Request User",
        renderCell: (cellValues: any): React.ReactElement => <GroupButtons cellValues={cellValues} />,
        minWidth: 200,
        align: "right",
        sortable: false,
        headerAlign: "right",
        hideable: false,
    },
];
interface DispatchProps {
    setSelectedUser(payload: UserData): SetSelectedUserAction;
}

interface StateProps {
    users: Nullable<UserData[]>;
    selectedRow: Nullable<UserData>;
}

type Props = DispatchProps & StateProps;

export class TestUserForm extends PureComponent<Props> {
    handleSelectRow(data: GridRowParams<any>) {
        this.props.setSelectedUser(data?.row);
    }

    render() {
        const {users, selectedRow}: Props = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={5} style={styles.dataGridWrapper}>
                    <DataGrid
                        onRowClick={this.handleSelectRow.bind(this)}
                        rowHeight={100}
                        rows={users || []}
                        columns={testColumns}
                        autoPageSize
                    />
                </Grid>
                <Grid item xs={7}>
                    {selectedRow ? <TabsWithDetails /> : null}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({notificationTest}: RootState): StateProps => ({
    users: selectUsers(notificationTest),
    selectedRow: selectSelectedUser(notificationTest),
});

const mapDispatchToProps = {
    setSelectedUser: notificationsTestActions.setSelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestUserForm);
