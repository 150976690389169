import axios, {AxiosRequestConfig, AxiosRequestHeaders} from "axios";

const axiosRequestConfigDefault: AxiosRequestConfig = {
    baseURL: "",
    headers: {
        "Content-Type": "application/json",
    } as AxiosRequestHeaders,
};

const firebaseGetInfoRequestConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_FIREBASE_API_BASE_URL,
    headers: {
        authorization: process.env.REACT_APP_FIREBASE_SERVER_KEY as string,
        "Content-Type": "application/json",
    },
};

const firebaseSendNotificationRequestConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_FCM_GOOGLE_API_BASE_URL,
    headers: {
        authorization: process.env.REACT_APP_FIREBASE_SERVER_KEY as string,
        "Content-Type": "application/json",
    },
};

export const axiosClient = axios.create(axiosRequestConfigDefault);

export const firebaseGetInfoRequestConfigAxiosClient = axios.create(firebaseGetInfoRequestConfig);

export const firebaseSendNotificationAxiosClient = axios.create(firebaseSendNotificationRequestConfig);
