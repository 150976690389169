import {AxiosResponse} from "axios";
import {all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest} from "redux-saga/effects";
import notificationFirebaseApi from "src/app-services/notifications/api";
import {SendPushNotificationT, setLoadingDataAboutTokenAction} from "src/app-services/notifications/interface";
import {RootState} from "~redux/reducers/interface";
import {APPROVE_USER_PERMISSIONS, DECLINE_USER_PERMISSIONS} from "./constants";
import {
    ApproveUserPermissionsAction,
    DeclineUserPermissionsAction,
    UpdateAnyFieldOfUserAction,
    UserDataKeys,
} from "./interface";
import {notificationsTestActions} from "./reducers";
// eslint-disable-next-line max-len
import {SupportedAppScreens} from "./tabs-with-details/test-push-notifications/send-notification-modal-body/select-app/interface";

type ApproveUserPermissionsSagaGeneratorType = Generator<
    CallEffect<RootState | SendPushNotificationT | AxiosResponse<any, any>> | PutEffect<setLoadingDataAboutTokenAction>,
    void,
    RootState & SendPushNotificationT
>;

function* approveUserPermissionsSaga({payload}: ApproveUserPermissionsAction): ApproveUserPermissionsSagaGeneratorType {
    try {
        yield call(notificationFirebaseApi.sendPushNotification, {
            token: payload || "",
            notification: {
                title: "Your request is approved!",
                body: "You can enjoy in vroome services!",
            },
            pageInApp: SupportedAppScreens.VERIFICATIONS,
        });

        yield put(
            notificationsTestActions.updateAnyFieldOfUser({field: UserDataKeys.REQUEST_USER, updatedFieldValue: false})
        );
        yield put(
            notificationsTestActions.updateAnyFieldOfUser({field: UserDataKeys.HAS_PERMISSIONS, updatedFieldValue: "1"})
        );
    } catch (error) {
        console.log("Something went wrong, approveUserPermissionsSaga", error);
    }
}

type DeclineUserPermissionsSagaGeneratorType = Generator<
    CallEffect<SendPushNotificationT | AxiosResponse<any, any>> | PutEffect<UpdateAnyFieldOfUserAction>,
    void,
    SendPushNotificationT
>;

function* declineUserPermissionsSaga({payload}: DeclineUserPermissionsAction): DeclineUserPermissionsSagaGeneratorType {
    try {
        yield call(notificationFirebaseApi.sendPushNotification, {
            token: payload || "",
            notification: {
                title: "Your request is declined!",
                body: "Please check your documents, and try again!",
            },
        });

        yield put(
            notificationsTestActions.updateAnyFieldOfUser({field: UserDataKeys.REQUEST_USER, updatedFieldValue: false})
        );
        yield put(
            notificationsTestActions.updateAnyFieldOfUser({
                field: UserDataKeys.HAS_PERMISSIONS,
                updatedFieldValue: "-1",
            })
        );
    } catch (error) {
        console.log("Something went wrong, declineUserPermissionsSaga", error);
    }
}

export default function* notificationsTestSaga(): IterableIterator<AllEffect<ForkEffect>> {
    yield all([
        takeLatest(APPROVE_USER_PERMISSIONS, approveUserPermissionsSaga),
        takeLatest(DECLINE_USER_PERMISSIONS, declineUserPermissionsSaga),
    ]);
}
