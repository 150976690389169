import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {Box, Button, IconButton, Theme, Typography, useTheme} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AnyAction} from "redux";
import {getFacts} from "src/feature/home-test/actions";
import {FactsApiResponse} from "src/feature/home-test/api";
import {RootState} from "~redux/reducers/interface";
import {asyncToggleTheme} from "~theme/reducers";
import {Nullable} from "~utils/utils";
import styles from "./styles.module.scss";

export default function HomeTest() {
    const dispatch = useDispatch();
    const theme: Theme = useTheme();
    const darkMode: boolean = useSelector((state: RootState) => state.theme.darkMode);
    const fact: Nullable<FactsApiResponse> = useSelector((state: RootState) => state.home.fact);

    return (
        <div className={styles.facts}>
            <span>Home page</span>
            <div>
                <Button variant="contained" onClick={() => dispatch(getFacts())}>
                    Fetch random fact about cats
                </Button>
            </div>
            <p>{fact?.fact}</p>

            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "background.default",
                    color: "text.primary",
                    borderRadius: 1,
                    p: 3,
                }}
            >
                <Typography>{darkMode ? "Dark" : "Light"} Mode</Typography>
                <IconButton
                    sx={{ml: 1}}
                    onClick={() => dispatch(asyncToggleTheme() as unknown as AnyAction)}
                    color="inherit"
                >
                    {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
            </Box>
        </div>
    );
}
