import "./index.scss";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from "react";
import {createRoot} from "react-dom/client";
import {axiosClient} from "~axios/axiosInstance";
import {setupInterceptorsTo} from "~axios/interceptors";
import "~i18n/i18n";
import {ReduxProvider} from "~redux/store/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// setup axios interceptors
setupInterceptorsTo(axiosClient);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ReduxProvider>
            <App />
        </ReduxProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
