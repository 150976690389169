import {all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest} from "redux-saga/effects";
import {setFacts} from "src/feature/home-test/actions";
import {GET_FACTS, GET_USERS, VERIFY_USER} from "src/feature/home-test/constants";
import {SetFactsAction, SetUsersAction, VerifyUserAction} from "src/feature/home-test/interface";
import {notificationsTestActions} from "src/feature/test-send-notifications-app/reducers";
import {Nullable} from "~utils/utils";
import homeAPI, {
    FactsApiResponse,
    FactsApiServiceResponse,
    UsersApiServiceResponse,
    VerifyUserApiResponse,
    VerifyUserApiServiceResponse,
} from "./api";

type FactsSagaSagaGeneratorType = Generator<
    CallEffect<FactsApiServiceResponse> | PutEffect<SetFactsAction>,
    void,
    FactsApiServiceResponse
>;

function* factsSaga(): FactsSagaSagaGeneratorType {
    let response: Nullable<FactsApiServiceResponse> = null;
    try {
        response = yield call(homeAPI.facts);
    } catch (error: any) {
        // console.log();
    }
    if (response?.data) {
        yield put(setFacts(response.data as unknown as FactsApiResponse));
    }
}

type FetchUsersSagaSagaGeneratorType = Generator<
    CallEffect<UsersApiServiceResponse> | PutEffect<SetUsersAction>,
    void,
    UsersApiServiceResponse
>;

function* fetchUsers(): FetchUsersSagaSagaGeneratorType {
    let response: Nullable<UsersApiServiceResponse> = null;
    try {
        response = yield call(homeAPI.getUsers);
    } catch (error: any) {
        // console.log("fetch users catch", error);
    }
    if (response?.data.data.length) {
        yield put(notificationsTestActions.setUsers(response.data.data));
    }
}

type VerifyUserSagaSagaGeneratorType = Generator<
    CallEffect<VerifyUserApiServiceResponse> | PutEffect,
    void,
    VerifyUserApiServiceResponse
>;

function* verifyUser({payload}: VerifyUserAction): VerifyUserSagaSagaGeneratorType {
    let response: Nullable<VerifyUserApiResponse> = null;
    try {
        response = yield call(homeAPI.verifyUser, {userID: payload as unknown as string});
    } catch (error: any) {
        // console.log("verify user catch", error);
    }
    console.log("verify user", response);
}

export default function* homeSaga(): IterableIterator<AllEffect<ForkEffect>> {
    yield all([
        takeLatest(GET_FACTS, factsSaga),
        takeLatest(GET_USERS, fetchUsers),
        takeLatest(VERIFY_USER, verifyUser),
    ]);
}
