import {Box, Button, Container, CssBaseline, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";

function Unauthorized() {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1">Unauthorized</Typography>
                <Typography>You do not have access to the requested page.</Typography>
                <Button onClick={goBack} variant="contained">
                    Back Home
                </Button>
            </Box>
        </Container>
    );
}

export default Unauthorized;
