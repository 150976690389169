import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import PendingIcon from "@mui/icons-material/Pending";
import React from "react";

function RenderApproveIcon(params: any) {
    if (params.row.hasPermissions === "0") {
        return <PendingIcon color="disabled" />;
    }
    if (params.row.hasPermissions === "1") {
        return <DoneIcon color="success" />;
    }
    return <CloseIcon color="error" />;
}
export default RenderApproveIcon;
