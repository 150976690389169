import {Box, Button, CircularProgress, Grid, IconButton, ListItem, ListItemText, Modal, TextField} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import TokenIcon from "@mui/icons-material/Token";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {verificationToken} from "src/app-services/notifications/actions";
import {verificationTokenAction} from "src/app-services/notifications/interface";
import NotificationService from "src/app-services/notifications/NotificationService";
import {selectloadingDataAboutToken, selectTokenExistStatus} from "src/app-services/notifications/reducers";
import {RootState} from "~redux/reducers/interface";
import {Nullable} from "~utils/utils";
import {APP_SUPPORTED_TOPICS_ARRAY} from "../../interface";
import {selectTokenOfSelectedUser} from "../../reducers";
import SendNotificationModalBody from "./send-notification-modal-body/SendNotificationModalBody";
import SendToItem from "./send-to-item/SendToItem";
import {styles, stylesSX} from "./styles";

export const inputNotificationTitleRef: any = React.createRef();
export const inputNotificationBodyRef: any = React.createRef();

interface OwnProps {
    withoutSendingToToken?: boolean;
    withoutCheckingTopicsForUser?: boolean;
}
interface OwnState {
    subscribedTopics: string[];
    displayModal: boolean;
    verificationTopicsLoading: boolean;
}

interface DispatchProps {
    verificationToken(): verificationTokenAction;
}

interface StateProps {
    token: Nullable<string>;
    tokenExistStatus: number;
    loading: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;
type State = OwnState;
class TestPushNotifications extends PureComponent<Props, State> {
    state: State = {
        subscribedTopics: [],
        displayModal: false,
        verificationTopicsLoading: false,
    };

    sendNotificationToTopic: Nullable<string> = "";

    constructor(props: Props) {
        super(props);
        this.getSubscribedTopics = this.getSubscribedTopics.bind(this);
        this.topicIsVerified = this.topicIsVerified.bind(this);
        this.setVerificationTopicsLoading = this.setVerificationTopicsLoading.bind(this);
    }

    componentDidMount() {
        this.getSubscribedTopics();
        this.props.verificationToken();
    }

    setVerificationTopicsLoading(loading: boolean): void {
        this.setState({verificationTopicsLoading: loading});
    }

    async getSubscribedTopics(): Promise<void> {
        if (!this.props.withoutSendingToToken) {
            this.setVerificationTopicsLoading(true);

            const subscribedTopics = await NotificationService.getTopicsSubscribed(this.props.token || "");

            this.setState({subscribedTopics});

            this.setVerificationTopicsLoading(false);
        }
    }

    setDisplayModal(display: boolean): void {
        this.setState({displayModal: display});
    }

    displayModal(topic?: Nullable<string>): void {
        if (inputNotificationTitleRef?.current?.value) {
            this.setDisplayModal(true);
            this.sendNotificationToTopic = topic;
        } else {
            alert("Please fill title!");
        }
    }

    topicIsVerified(supportedTopic: string): boolean {
        const {subscribedTopics}: State = this.state;

        if (this.props.withoutCheckingTopicsForUser) {
            return true;
        }

        if (subscribedTopics?.length > 0) {
            return subscribedTopics?.includes(supportedTopic);
        }

        return false;
    }

    render() {
        const {withoutSendingToToken, withoutCheckingTopicsForUser, tokenExistStatus, token, loading}: Props =
            this.props;

        const {verificationTopicsLoading}: State = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextField
                        inputRef={inputNotificationTitleRef}
                        label="Push Notification Title"
                        style={styles.textField}
                        required
                    />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        inputRef={inputNotificationBodyRef}
                        id="filled-multiline-flexible"
                        label="Push Notification Body"
                        multiline
                        minRows={4}
                        variant="filled"
                        style={styles.textField}
                    />
                </Grid>

                <Grid item xs={12}>
                    {!withoutSendingToToken && (
                        <>
                            <Button
                                disabled={tokenExistStatus < 1}
                                variant="contained"
                                onClick={this.displayModal.bind(this, undefined)}
                                startIcon={<TokenIcon />}
                            >
                                Send to token
                            </Button>
                            <IconButton style={styles.iconButtonTokenRefresh} onClick={this.props.verificationToken}>
                                {loading ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
                            </IconButton>
                        </>
                    )}

                    <Grid item xs={12} style={styles.wrapperList}>
                        <Grid item xs={12}>
                            <ListItem>
                                {!withoutSendingToToken && (
                                    <IconButton
                                        style={styles.iconButtonTopicRefresh}
                                        onClick={this.getSubscribedTopics}
                                    >
                                        {verificationTopicsLoading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : (
                                            <RefreshIcon />
                                        )}
                                    </IconButton>
                                )}
                                <ListItemText
                                    style={styles.topicsHeadingLabel}
                                    primary={!withoutCheckingTopicsForUser ? "Verification topics:" : "Send to topic:"}
                                />
                            </ListItem>
                        </Grid>

                        {APP_SUPPORTED_TOPICS_ARRAY?.map((topic) => (
                            <Grid item xs={6}>
                                <SendToItem
                                    label={topic}
                                    onClick={this.displayModal.bind(this, topic)}
                                    withoutVerifiedTopic={withoutCheckingTopicsForUser}
                                    topicVerified={this.topicIsVerified(topic)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Modal
                    open={this.state.displayModal}
                    onClose={this.setDisplayModal.bind(this, false)}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={stylesSX.holderModalBody}>
                        <SendNotificationModalBody
                            closeModal={this.setDisplayModal.bind(this, false)}
                            token={token || ""}
                            topic={this.sendNotificationToTopic || undefined}
                        />
                    </Box>
                </Modal>
            </Grid>
        );
    }
}

const mapStateToProps = ({notificationTest, appNotifications}: RootState): StateProps => ({
    token: selectTokenOfSelectedUser(notificationTest),
    tokenExistStatus: selectTokenExistStatus(appNotifications),
    loading: selectloadingDataAboutToken(appNotifications),
});

const mapDispatchToProps = {
    verificationToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPushNotifications);
