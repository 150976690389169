export enum HomeRouteNames {
    HOME_TEST = "Home Test",
    USER = "User",
    TEST_PUST_TOPICS = "Test push topics",
}

export enum HomeRoutePaths {
    HOME_TEST = "/",
    USER = "/user",
    TEST_PUST_TOPICS = "/push-topics",
}
