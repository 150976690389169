import {Grid, List} from "@mui/material";
import React, {PureComponent} from "react";
import {UserData} from "src/feature/home-test/interface";
import {Nullable} from "~utils/utils";
import ListElement from "./list-element/ListElement";
import {UserDetailsIcons} from "./list-element/interface";

interface OwnProps {
    user: Nullable<UserData>;
}
type Props = OwnProps;
class UserDetails extends PureComponent<Props> {
    render() {
        const {user}: Props = this.props;

        return (
            <Grid container spacing={2}>
                <List sx={{bgcolor: "background.paper"}}>
                    <ListElement
                        text={`${user?.firstName || ""} ${user?.lastName || ""}`}
                        icon={UserDetailsIcons.USER}
                    />

                    <ListElement text={user?.age?.toString() || ""} icon={UserDetailsIcons.AGE} />

                    <ListElement
                        text={
                            user?.hasPermissions === "1" ? "Has approved permissions" : "Has not approved permissions"
                        }
                        icon={UserDetailsIcons.PERMISSIONS}
                    />

                    <ListElement
                        text={user?.hasPermissions === "1" ? "Master" : "Passenger"}
                        icon={UserDetailsIcons.ROLE}
                    />
                </List>
            </Grid>
        );
    }
}

export default UserDetails;
