import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SecurityIcon from "@mui/icons-material/Security";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import TodayIcon from "@mui/icons-material/Today";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import React, {PureComponent} from "react";
import {Nullable} from "~utils/utils";
import {UserDetailsIcons} from "./interface";

interface OwnProps {
    text?: string;
    icon?: string;
}

type Props = OwnProps;

class ListElement extends PureComponent<Props> {
    getIcon(): Nullable<React.ReactElement> {
        const {icon}: Props = this.props;

        switch (icon) {
            case UserDetailsIcons.USER:
                return <AccountCircleIcon />;

            case UserDetailsIcons.AGE:
                return <TodayIcon />;

            case UserDetailsIcons.PERMISSIONS:
                return <SecurityIcon />;

            case UserDetailsIcons.ROLE:
                return <SensorOccupiedIcon />;

            default:
                return null;
        }
    }

    render() {
        const {text}: Props = this.props;
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar>{this.getIcon()}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={text} />
            </ListItem>
        );
    }
}

export default ListElement;
