import {PureComponent} from "react";
import {Nullable} from "~utils/utils";
import notificationFirebaseApi from "./api";
import {TokenDataT} from "./interface";

class NotificationService extends PureComponent {
    public static async getDataAboutToken(token: string): Promise<Nullable<TokenDataT>> {
        let responseInfo: Nullable<TokenDataT>;

        await notificationFirebaseApi
            .getTokenInfo(token)
            .then((res) => {
                responseInfo = res.data;
            })
            .catch((err) => {
                responseInfo = err?.response?.data;

                console.log("getDataAboutToken error", err);
            });

        return responseInfo;
    }

    public static async getTopicsSubscribed(token: string): Promise<string[]> {
        const tokenData: Nullable<TokenDataT> = await NotificationService.getDataAboutToken(token);

        let topicsKeysInArray: string[] = [];

        if (tokenData && !tokenData?.error) {
            const responseTopics = tokenData?.rel?.topics;
            topicsKeysInArray = responseTopics ? Object.keys(responseTopics) : [];
        }

        return topicsKeysInArray;
    }
}

export default NotificationService;
