import {Box, Button, Grid, Modal, TextField} from "@mui/material";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {
    UpdateAnyFieldOfUserAction,
    UpdateAnyFieldOfUserT,
    UserDataKeys,
} from "src/feature/test-send-notifications-app/interface";
import {notificationsTestActions} from "src/feature/test-send-notifications-app/reducers";
import {Nullable} from "~utils/utils";
import {style} from "./styles";

const inputRef: any = React.createRef();

interface OwnProps {
    displayModal?: boolean;
    setDisplayModal: (display: boolean) => void;
    currentToken?: Nullable<string>;
}

interface DispatchProps {
    updateAnyFieldOfUser(payload: UpdateAnyFieldOfUserT): UpdateAnyFieldOfUserAction;
}

type Props = OwnProps & DispatchProps;

class ModalUpdateToken extends PureComponent<Props> {
    hideModal(): void {
        const {setDisplayModal}: Props = this.props;

        if (setDisplayModal) {
            setDisplayModal(false);
        }
    }

    saveNewToken(): void {
        const {updateAnyFieldOfUser}: Props = this.props;

        if (updateAnyFieldOfUser) {
            updateAnyFieldOfUser({
                field: UserDataKeys.PUSH_TOKEN,
                updatedFieldValue: inputRef?.current?.value,
            });
        }

        this.hideModal();
    }

    render() {
        const {displayModal, currentToken} = this.props;
        return (
            <Modal
                open={displayModal || false}
                onClose={this.hideModal.bind(this)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...style, width: 400}}>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={inputRef}
                            id="filled-multiline-flexible"
                            label="Edit Token"
                            multiline
                            minRows={4}
                            variant="filled"
                            style={{width: "100%", display: "flex", flex: 1}}
                            defaultValue={currentToken}
                        />
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 10}}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={this.saveNewToken.bind(this)}
                            style={{float: "right"}}
                        >
                            Save Token
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    updateAnyFieldOfUser: notificationsTestActions.updateAnyFieldOfUser,
};

export default connect(undefined, mapDispatchToProps)(ModalUpdateToken);
