import {UserData} from "src/feature/home-test/interface";
import {
    APPROVE_USER_PERMISSIONS,
    DECLINE_USER_PERMISSIONS,
    SET_SELECTED_USER,
    UPDATE_ANY_FIELD_OF_USER,
    UPDATE_WHOLE_OBJECT_OF_USER,
} from "./constants";

export interface UsersDataT {
    id: number;
    lastName: string;
    firstName: string;
    age?: number;
    pushToken?: string;
    requestUser?: boolean;
    hasPermissions?: string;
}

export interface NotificationsTestState {
    users: UserData[];
    selectedUser?: UserData;
}

export interface UpdateAnyFieldOfUserT {
    field: string;
    updatedFieldValue: any;
}

export interface SetSelectedUserAction {
    type: typeof SET_SELECTED_USER;
    payload: UserData;
}

export interface UpdateAnyFieldOfUserAction {
    type: typeof UPDATE_ANY_FIELD_OF_USER;
    payload: UpdateAnyFieldOfUserT;
}

export interface UpdateWholeObjectOfUserAction {
    type: typeof UPDATE_WHOLE_OBJECT_OF_USER;
    payload: UserData;
}

export interface ApproveUserPermissionsAction {
    type: typeof APPROVE_USER_PERMISSIONS;
    payload: string;
}

export interface DeclineUserPermissionsAction {
    type: typeof DECLINE_USER_PERMISSIONS;
    payload: string;
}

export enum UserDataKeys {
    ID = "id",
    LAST_NAME = "lastName",
    FIST_NAME = "firstName",
    AGE = "age",
    PUSH_TOKEN = "pushToken",
    REQUEST_USER = "requestUser",
    HAS_PERMISSIONS = "hasPermissions",
}

export enum APP_SUPPORTED_TOPICS {
    VROOME_TEST = "vroome-test",
    VROOME_PROD = "vroome-prod",
    TEST_1 = "test1",
    TEST_2 = "test2",
    TEST_3 = "test3",
    TEST_4 = "test4",
    TEST_5 = "test5",
}

export const APP_SUPPORTED_TOPICS_ARRAY: string[] = Object.values(APP_SUPPORTED_TOPICS);
