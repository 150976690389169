import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserData} from "src/feature/home-test/interface";
import {Slices} from "~redux/reducers/interface";
import {Nullable} from "~utils/utils";
import {NotificationsTestState, UpdateAnyFieldOfUserT} from "./interface";

const initialState: NotificationsTestState = {
    users: [],
    selectedUser: undefined,
};

const notificationsTestSlice = createSlice({
    name: Slices.notificationsTest,
    initialState,
    reducers: {
        setUsers(state: NotificationsTestState, action: PayloadAction<UserData[]>) {
            state.users = action.payload;
        },
        setSelectedUser(state: NotificationsTestState, action: PayloadAction<UserData>) {
            state.selectedUser = action.payload;
        },

        updateAnyFieldOfUser(state: NotificationsTestState, action: PayloadAction<UpdateAnyFieldOfUserT>) {
            const {updatedFieldValue, field}: UpdateAnyFieldOfUserT = action.payload;

            const objectForUpdate: number | undefined = state?.users?.findIndex(
                (user) => user.id === state.selectedUser?.id
            );

            if (objectForUpdate >= 0 && state.users && field) {
                state.users[objectForUpdate][field as keyof UserData] = updatedFieldValue as never;

                if (state.selectedUser && field) {
                    state.selectedUser[field as keyof UserData] = updatedFieldValue as never;
                }
            }
        },

        updateWholeObjectOfUser(state: NotificationsTestState, action: PayloadAction<UserData>) {
            const objectForUpdate = state?.users?.findIndex((user) => user.id === state.selectedUser?.id);

            if (objectForUpdate >= 0 && state.users) {
                state.users[objectForUpdate] = action.payload;

                if (state.selectedUser) {
                    state.selectedUser = action.payload;
                }
            }
        },
    },
});

export const selectUsers = (notificationsTest: NotificationsTestState): Nullable<UserData[]> => notificationsTest.users;

export const selectSelectedUser = (notificationsTest: NotificationsTestState): Nullable<UserData> =>
    notificationsTest.selectedUser;

export const selectTokenOfSelectedUser = (notificationsTest: NotificationsTestState): Nullable<string> =>
    notificationsTest?.selectedUser?.pushToken;

export const notificationsTestActions = notificationsTestSlice.actions;
const notificationsTestReducer = notificationsTestSlice.reducer;

export default notificationsTestReducer;
