import {GET_DATA_ABOUT_TOKEN, GET_TOPICS_SUBSCRIBED, SET_TOKEN_EXIST_STATUS, VERIFICATION_TOKEN} from "./constants";
import {
    getDataAboutTokenAction,
    getTopicsSubscribedAction,
    setTokenExistStatusAction,
    verificationTokenAction,
} from "./interface";

export const getDataAboutToken = (): getDataAboutTokenAction => ({
    type: GET_DATA_ABOUT_TOKEN,
});

export const getTopicsSubscribed = (): getTopicsSubscribedAction => ({
    type: GET_TOPICS_SUBSCRIBED,
});

export const verificationToken = (): verificationTokenAction => ({
    type: VERIFICATION_TOKEN,
});

export const setLoadingDataAboutToken = (): getDataAboutTokenAction => ({
    type: GET_DATA_ABOUT_TOKEN,
});

export const setLoadingTopicsSubscribed = (): getTopicsSubscribedAction => ({
    type: GET_TOPICS_SUBSCRIBED,
});

export const setTokenExistStatus = (payload: number): setTokenExistStatusAction => ({
    type: SET_TOKEN_EXIST_STATUS,
    payload,
});
