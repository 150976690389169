import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React, {PureComponent} from "react";

interface OwnProps {
    children?: React.ReactNode;
    label: string;
    hasOpacity?: boolean;
    onClick?: () => void;
}

type Props = OwnProps;

class SidebarItem extends PureComponent<Props> {
    render() {
        const {children, label, hasOpacity, onClick}: Props = this.props;

        return (
            <ListItem key={label} disablePadding sx={{display: "block"}}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: hasOpacity ? "initial" : "center",
                        px: 2.5,
                    }}
                    onClick={onClick || undefined}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: hasOpacity ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {children}
                    </ListItemIcon>
                    <ListItemText primary={label} sx={{opacity: hasOpacity ? 1 : 0}} />
                </ListItemButton>
            </ListItem>
        );
    }
}

export default SidebarItem;
