import {AxiosResponse} from "axios";
import {SagaReturnType} from "redux-saga/effects";
import {UserData} from "src/feature/home-test/interface";
import {axiosClient} from "~axios/axiosInstance";

export interface FactsApiResponse {
    fact: string;
    length: number;
}
export type FactsApiService = Promise<AxiosResponse<FactsApiResponse>>;
export type FactsApiServiceResponse = SagaReturnType<typeof homeAPI.facts>;

export interface UsersApiResponse {
    data: UserData[];
    metadata: {
        continuationToken: string;
    };
}
export type UsersApiService = Promise<AxiosResponse<UsersApiResponse>>;
export type UsersApiServiceResponse = SagaReturnType<typeof homeAPI.getUsers>;

export interface VerifyUserApiResponse {
    data: any; // TODO: Mladen
}
export type VerifyUserApiService = Promise<AxiosResponse<VerifyUserApiResponse>>;
export type VerifyUserApiServiceResponse = SagaReturnType<typeof homeAPI.facts>;

const homeAPI = {
    async facts(): FactsApiService {
        return axiosClient.get("https://catfact.ninja/fact");
    },
    async getUsers(): UsersApiService {
        return axiosClient.get("https://dev-adminapi.vroome.us/user/search");
    },
    async verifyUser({userID}: {userID: string}) {
        return axiosClient.post(`https://dev-adminapi.vroome.us/user/verify/${userID}`);
    },
};

export default homeAPI;
