import {all, fork} from "redux-saga/effects";
import appNotificationsSaga from "src/app-services/notifications/sagas";
import homeSaga from "src/feature/home-test/sagas";
import notificationsTestSaga from "src/feature/test-send-notifications-app/sagas";

const rootSaga = function* rootSagas() {
    yield all([fork(homeSaga), fork(appNotificationsSaga), fork(notificationsTestSaga)]);
};

export default rootSaga;
