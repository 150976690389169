import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Slices} from "~redux/reducers/interface";
import {AppNotificationsState} from "./interface";

const initialState: AppNotificationsState = {
    loadingDataAboutToken: false,
    loadingTopicsSubscribed: false,
    tokenExistStatus: 0,
};

const appNotificationsSlice = createSlice({
    name: Slices.appNotiifcations,
    initialState,
    reducers: {
        setLoadingDataAboutToken(state: AppNotificationsState, action: PayloadAction<boolean>) {
            state.loadingDataAboutToken = action.payload;
        },
        setLoadingTopicsSubscribed(state: AppNotificationsState, action: PayloadAction<boolean>) {
            state.loadingTopicsSubscribed = action.payload;
        },
        setTokenExistStatus(state: AppNotificationsState, action: PayloadAction<number>) {
            state.tokenExistStatus = action.payload;
        },
    },
});

export const selectloadingDataAboutToken = (appNotiifcations: AppNotificationsState): boolean =>
    appNotiifcations.loadingDataAboutToken;
export const selectTokenExistStatus = (appNotiifcations: AppNotificationsState): number =>
    appNotiifcations.tokenExistStatus;

export const selectloadingTopicsSubscribed = (appNotiifcations: AppNotificationsState): boolean =>
    appNotiifcations.loadingTopicsSubscribed;

export const appNotificationsActions = appNotificationsSlice.actions;
const appNotificationsReducer = appNotificationsSlice.reducer;
export default appNotificationsReducer;
