import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {Button} from "@mui/material";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {approveUserPermissions, declineUserPermissions} from "../actions";
import {ApproveUserPermissionsAction, DeclineUserPermissionsAction} from "../interface";

interface OwnProps {
    cellValues: any;
}
interface DispatchProps {
    approvePermissions(payload: string): ApproveUserPermissionsAction;
    declinePermissions(payload: string): DeclineUserPermissionsAction;
}

type Props = OwnProps & DispatchProps;

export class GroupButtons extends PureComponent<Props> {
    handleUserPermissionsWithTokenForwarded(token: string, handlingType: string): void {
        const {approvePermissions, declinePermissions}: Props = this.props;

        if (handlingType === "approve") approvePermissions(token || "");
        else declinePermissions(token || "");
    }

    render() {
        const {cellValues}: Props = this.props;

        if (cellValues.row.requestUser) {
            return (
                <>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={this.handleUserPermissionsWithTokenForwarded.bind(
                            this,
                            cellValues.row.pushToken,
                            "approve"
                        )}
                    >
                        <DoneIcon />
                    </Button>

                    <Button
                        style={{marginLeft: 10}}
                        variant="contained"
                        color="error"
                        onClick={this.handleUserPermissionsWithTokenForwarded.bind(
                            this,
                            cellValues.row.pushToken,
                            "decline"
                        )}
                    >
                        <CloseIcon />
                    </Button>
                </>
            );
        }
        return "No Requests";
    }
}

const mapDispatchToProps = {
    approvePermissions: approveUserPermissions,
    declinePermissions: declineUserPermissions,
};
export default connect(undefined, mapDispatchToProps)(GroupButtons);
