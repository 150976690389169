import {Box} from "@mui/material";
import React, {PureComponent} from "react";
import {inputNotificationBodyRef, inputNotificationTitleRef} from "../../TestPushNotifications";
import styles from "./styles";

export class NotificationBox extends PureComponent {
    render() {
        return (
            <Box sx={styles.boxSXStyles} style={styles.boxStyle}>
                <b>{inputNotificationTitleRef?.current?.value}</b>
                <p>{inputNotificationBodyRef?.current?.value}</p>
            </Box>
        );
    }
}

export default NotificationBox;
