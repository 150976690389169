import {ThemeProvider} from "@emotion/react";
import {createTheme, CssBaseline, PaletteMode} from "@mui/material";
import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Unauthorized from "src/auth/Unauthorized";
import Home from "~pages/home/Home";
import {HomeRoutePaths} from "~pages/home/routes";
// eslint-disable-next-line max-len
import {AuthProvider, TAuthConfig} from "react-oauth2-code-pkce";
// eslint-disable-next-line max-len
import TestPushNotifications from "src/feature/test-send-notifications-app/tabs-with-details/test-push-notifications/TestPushNotifications";
import TestUserForm from "src/feature/test-send-notifications-app/TestUserForm";
import Page404 from "~pages/page404/Page404";
import SignIn from "~pages/signIn/SignIn";
import {RootState} from "~redux/store/store";
import {getDesignTokens} from "~theme/Theme";
import "./App.scss";
import HomeTest from "./feature/home-test/HomeTest";

const authConfig: TAuthConfig = {
    clientId: "backoffice",
    authorizationEndpoint: "https://dev-auth.vroome.us/oauth2/authorize",
    tokenEndpoint: "https://dev-auth.vroome.us/oauth2/token",
    logoutEndpoint: "https://dev-admin.vroome.us/login",
    redirectUri: "https://dev-admin.vroome.us/",
    scope: "profile openid",
    decodeToken: false,
    autoLogin: false,
};

export default function App() {
    const [mode, setMode] = useState<PaletteMode>("light");
    const darkMode: boolean = useSelector((state: RootState) => state.theme.darkMode);

    useMemo(() => {
        if (darkMode) {
            setMode("dark");
        } else {
            setMode("light");
        }
    }, [darkMode]);

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider authConfig={authConfig}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route element={<SignIn />} path="/login" />
                        <Route path="unauthorized" element={<Unauthorized />} />

                        {/* <Route path="/" element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}> */}
                        <Route element={<Home />}>
                            <Route element={<HomeTest />} path={HomeRoutePaths.HOME_TEST} />
                            <Route element={<TestUserForm />} path={HomeRoutePaths.USER} />
                            <Route
                                element={<TestPushNotifications withoutSendingToToken withoutCheckingTopicsForUser />}
                                path={HomeRoutePaths.TEST_PUST_TOPICS}
                            />
                        </Route>
                        {/* </Route> */}

                        <Route element={<Page404 />} path="/*" />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    );
}
