import {FactsApiResponse} from "src/feature/home-test/api";
import {GET_FACTS, GET_USERS, SET_FACTS, SET_TOKEN, SET_USERS, VERIFY_USER} from "src/feature/home-test/constants";
import {Nullable} from "~utils/utils";
import {
    GetFactsAction,
    GetUsersAction,
    SetFactsAction,
    SetTokenAction,
    SetUsersAction,
    UserData,
    VerifyUserAction,
} from "./interface";

export const getFacts = (): GetFactsAction => ({
    type: GET_FACTS,
});

export const setFacts = (payload: Nullable<FactsApiResponse>): SetFactsAction => ({
    type: SET_FACTS,
    payload,
});

export const setToken = (payload: string): SetTokenAction => ({
    type: SET_TOKEN,
    payload,
});

export const getUsers = (): GetUsersAction => ({
    type: GET_USERS,
});

export const setUsers = (payload: UserData[]): SetUsersAction => ({
    type: SET_USERS,
    payload,
});

export const verifyUser = (payload: string): VerifyUserAction => ({
    type: VERIFY_USER,
    payload,
});
