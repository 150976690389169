import {AxiosResponse} from "axios";
import {SagaReturnType} from "redux-saga/effects";
import {firebaseGetInfoRequestConfigAxiosClient, firebaseSendNotificationAxiosClient} from "~axios/axiosInstance";
import {PushNotificationDataResT, SendPushNotificationT, SendToT, TokenDataT} from "./interface";

export type GetTokenInfoApiServiceT = Promise<AxiosResponse<TokenDataT>>;
export type GetTokenInfoApiServiceResponse = SagaReturnType<typeof notificationFirebaseApi.getTokenInfo>;

export type SendPushNotificationApiServiceT = Promise<AxiosResponse<PushNotificationDataResT>>;
export type SendPushNotificationApiServiceResponse = SagaReturnType<
    typeof notificationFirebaseApi.sendPushNotification
>;

const notificationFirebaseApi = {
    async getTokenInfo(token: string): GetTokenInfoApiServiceT {
        return firebaseGetInfoRequestConfigAxiosClient.get(`/iid/info/${token}?details=true`);
    },

    async sendPushNotification({
        token = "",
        topic = "",
        registrationTokens = [],
        notification,
        pageInApp,
    }: SendPushNotificationT): SendPushNotificationApiServiceT {
        const sendTo: SendToT = {
            to: topic ? `/topics/${topic}` : token,
        };

        if (topic && registrationTokens.length > 0) {
            sendTo.registration_tokens = registrationTokens;
        }

        return firebaseSendNotificationAxiosClient.post("/fcm/send", {
            ...sendTo,

            notification,
            data: {
                type: pageInApp,
            },
        });
    },
};

export default notificationFirebaseApi;
