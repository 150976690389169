import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FactsApiResponse} from "src/feature/home-test/api";
import {HomeState} from "src/feature/home-test/interface";
import {Slices} from "~redux/reducers/interface";
import {Nullable} from "~utils/utils";

const initialState: HomeState = {
    fact: undefined,
    token: undefined,
};

const homeSlice = createSlice({
    name: Slices.home,
    initialState,
    reducers: {
        setFacts(state: HomeState, action: PayloadAction<FactsApiResponse>) {
            state.fact = action.payload;
        },
        setToken(state: HomeState, action: PayloadAction<string>) {
            state.token = action.payload;
        },
    },
});

export const selectFact = (home: HomeState): Nullable<FactsApiResponse> => home.fact;
export const selectToken = (home: HomeState): Nullable<string> => home.token;

export const homeActions = homeSlice.actions;
const homeReducer = homeSlice.reducer;
export default homeReducer;
