import {Typography} from "@mui/material";
import React, {PureComponent} from "react";
import notificationFirebaseApi from "src/app-services/notifications/api";
import {SendPushNotificationT} from "src/app-services/notifications/interface";
import {inputNotificationBodyRef, inputNotificationTitleRef} from "../TestPushNotifications";
import BottomButtonGroup from "./bottom-button-group/BottomButtonGroup";
import NotificationBox from "./notification-box/NotificationBox";
import SelectApp, {selectAppRef} from "./select-app/SelectApp";

interface OwnProps {
    closeModal: () => void;
    token?: string;
    topic?: string;
}

type Props = OwnProps;

class SendNotificationModalBody extends PureComponent<Props> {
    async sendPushNotification(): Promise<void> {
        const {topic, token}: Props = this.props;

        const notificationObj: SendPushNotificationT = {
            topic,
            token,
            pageInApp: selectAppRef.current.value,
            notification: {
                title: inputNotificationTitleRef.current.value,
                body: inputNotificationBodyRef.current.value,
            },
        };

        await notificationFirebaseApi.sendPushNotification(notificationObj);
    }

    render() {
        const {closeModal, topic}: Props = this.props;

        return (
            <>
                <Typography variant="body1" style={{marginBottom: 10}}>
                    Your notification:
                </Typography>

                <NotificationBox />

                <SelectApp />

                <Typography variant="body1" style={{marginTop: 30}}>
                    Send to{`${topic ? " topic" : ""}`}: <b>{topic || "token"}</b>
                </Typography>

                <BottomButtonGroup
                    cancelOnClick={closeModal}
                    sendPushNotification={this.sendPushNotification.bind(this)}
                />
            </>
        );
    }
}

export default SendNotificationModalBody;
