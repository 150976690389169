/* eslint-disable jsx-a11y/anchor-is-valid */
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

export default function SignIn() {
    const {login, error}: IAuthContext = React.useContext(AuthContext);

    const handleSubmit = () => {
        login();
    };

    console.log("login error", error);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        disabled
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        disabled
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                        disabled
                    />

                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disabled>
                        Sign In
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                        Alternative Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
