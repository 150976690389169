import {all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest} from "redux-saga/effects";
import {RootState} from "~redux/reducers/interface";
import {getReduxState} from "~redux/sagas/utils";
import {Nullable} from "~utils/utils";
import {GET_DATA_ABOUT_TOKEN, VERIFICATION_TOKEN} from "./constants";
import {setLoadingDataAboutTokenAction, setTokenExistStatusAction, TokenDataT} from "./interface";
import NotificationService from "./NotificationService";
import {appNotificationsActions} from "./reducers";

type GetDataAboutTokenSagaGeneratorType = Generator<
    CallEffect<RootState | Nullable<TokenDataT>> | PutEffect<setLoadingDataAboutTokenAction>,
    Nullable<TokenDataT>,
    RootState & Nullable<TokenDataT>
>;

function* getDataAboutTokenSaga(): GetDataAboutTokenSagaGeneratorType {
    yield put(appNotificationsActions.setLoadingDataAboutToken(false));

    const {
        notificationTest: {selectedUser},
    }: RootState = yield call(getReduxState);

    yield put(appNotificationsActions.setLoadingDataAboutToken(true));

    const tokenData: Nullable<TokenDataT> = yield call(
        NotificationService.getDataAboutToken,
        selectedUser?.pushToken || ""
    );

    yield put(appNotificationsActions.setLoadingDataAboutToken(false));

    return tokenData;
}

type verificationTokenSagaGeneratorType = Generator<
    CallEffect<GetDataAboutTokenSagaGeneratorType | Nullable<TokenDataT> | void> | PutEffect<setTokenExistStatusAction>,
    void,
    Nullable<TokenDataT>
>;

function* verificationTokenSaga(): verificationTokenSagaGeneratorType {
    try {
        const tokenData: Nullable<TokenDataT> = yield call(getDataAboutTokenSaga);

        if (tokenData && !tokenData.error) {
            yield put(appNotificationsActions.setTokenExistStatus(1));
        } else {
            yield put(appNotificationsActions.setTokenExistStatus(-1));
        }
    } catch (error) {
        yield put(appNotificationsActions.setTokenExistStatus(-1));
        console.log("Something went wrong, getDataAboutTokenSaga", error);
    }
}

export default function* appNotificationsSaga(): IterableIterator<AllEffect<ForkEffect>> {
    yield all([
        takeLatest(GET_DATA_ABOUT_TOKEN, getDataAboutTokenSaga),
        takeLatest(VERIFICATION_TOKEN, verificationTokenSaga),
    ]);
}
