import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import enTranslations from "~i18n/languages/en.json";
import srTranslations from "~i18n/languages/sr.json";

const resources = {
    en: {
        translation: enTranslations,
    },
    sr: {
        translation: srTranslations,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem("activeLang") || "en",
    fallbackLng: "en",

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
