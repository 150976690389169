const styles = {
    wrapperButtonGroup: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        marginBottom: -10,
    },
    textInput: {
        width: "100%",
        display: "flex",
        flex: 1,
    },
    verificationButton: {
        float: "right",
        minWidth: 100,
        minHeight: 30,
    },
};
export default styles;
