import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {PureComponent} from "react";
import {SupportedAppScreens} from "./interface";

export const selectAppRef: any = React.createRef();
interface OwnState {
    selectedApp: string;
}

type State = OwnState;
export class SelectApp extends PureComponent<any, State> {
    state: State = {
        selectedApp: "",
    };

    handleChange(event: SelectChangeEvent) {
        this.setState({selectedApp: event.target.value as string});
    }

    render() {
        return (
            <Box sx={{minWidth: 120, marginTop: 2}}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">Select App Screen</InputLabel>
                    <Select
                        inputRef={selectAppRef}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.selectedApp}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={SupportedAppScreens.VROOME_APP}>{SupportedAppScreens.VROOME_APP}</MenuItem>
                        <MenuItem value={SupportedAppScreens.CHAT}>{SupportedAppScreens.CHAT}</MenuItem>
                        <MenuItem value={SupportedAppScreens.VERIFICATIONS}>
                            {SupportedAppScreens.VERIFICATIONS}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        );
    }
}

export default SelectApp;
