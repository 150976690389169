import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import TokenIcon from "@mui/icons-material/Token";
import {Button} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import {CSSObject, styled, Theme, useTheme} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useContext, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useDispatch} from "react-redux";
import {Link, Outlet} from "react-router-dom";
import {getUsers, setToken} from "src/feature/home-test/actions";
import SignIn from "~pages/signIn/SignIn";
import {HomeRouteNames, HomeRoutePaths} from "./routes";
import SidebarItem from "./sidebar-item/SidebarItem";
import styles from "./styles.module.scss";
// eslint-disable-next-line max-len

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function Home() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const {token, logOut} = useContext<IAuthContext>(AuthContext);
    const dispatch = useDispatch();

    if (token) {
        dispatch(setToken(token));
        localStorage.setItem("token", token);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return token ? (
        <Box sx={{display: "flex"}}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: "none"}),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        VROOME
                    </Typography>
                    <Button variant="contained" onClick={() => logOut()}>
                        logOut
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <List>
                    <Link to={HomeRoutePaths.HOME_TEST} className={styles.linkRouter}>
                        <SidebarItem label={HomeRouteNames.HOME_TEST} hasOpacity={open}>
                            <HomeIcon />
                        </SidebarItem>
                    </Link>
                    <Link to={HomeRoutePaths.USER} className={styles.linkRouter}>
                        <SidebarItem
                            label={HomeRouteNames.USER}
                            hasOpacity={open}
                            onClick={() => {
                                dispatch(getUsers());
                            }}
                        >
                            <PeopleIcon />
                        </SidebarItem>
                    </Link>
                    <Link to={HomeRoutePaths.TEST_PUST_TOPICS} className={styles.linkRouter}>
                        <SidebarItem label={HomeRouteNames.TEST_PUST_TOPICS} hasOpacity={open}>
                            <TokenIcon />
                        </SidebarItem>
                    </Link>
                </List>
            </Drawer>

            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader />

                <Outlet />
            </Box>
        </Box>
    ) : (
        <SignIn />
    );
}
