import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import {IconButton, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import React, {PureComponent} from "react";

interface OwnProps {
    onClick?: () => void;
    label: string;
    topicVerified?: boolean;
    withoutVerifiedTopic?: boolean;
}

type Props = OwnProps;

class SendToItem extends PureComponent<Props> {
    getIconForVerifiedTopic() {
        const {topicVerified} = this.props;

        if (topicVerified) {
            return <MobileFriendlyIcon color="success" />;
        }
        return <PhonelinkEraseIcon color="disabled" />;
    }

    renderListItemAvatarChildren() {
        const {withoutVerifiedTopic, onClick} = this.props;

        if (withoutVerifiedTopic) {
            return (
                <IconButton onClick={onClick}>
                    <SendToMobileIcon />
                </IconButton>
            );
        }

        return this.getIconForVerifiedTopic();
    }

    render() {
        const {label}: Props = this.props;
        return (
            <ListItem>
                <ListItemAvatar>{this.renderListItemAvatarChildren()}</ListItemAvatar>
                <ListItemText primary={label} />
            </ListItem>
        );
    }
}

export default SendToItem;
