import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import {Button, CircularProgress, Grid, IconButton, Snackbar, TextField} from "@mui/material";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {verificationToken} from "src/app-services/notifications/actions";
import {setTokenExistStatusAction, verificationTokenAction} from "src/app-services/notifications/interface";
import {
    appNotificationsActions,
    selectloadingDataAboutToken,
    selectTokenExistStatus,
} from "src/app-services/notifications/reducers";
import {RootState} from "~redux/reducers/interface";
import {SnackbarDataT} from "./interface";
import ModalUpdateToken from "./modal-update-token/ModalUpdateToken";
import styles from "./styles";

interface OwnProps {
    token?: string;
}
interface OwnState {
    displayModalForEditToken: boolean;
}

interface DispatchProps {
    verificationToken(): verificationTokenAction;
    setTokenExistStatus(payload: number): setTokenExistStatusAction;
}

interface StateProps {
    loading: boolean;
    tokenExistStatus: number;
}

type Props = OwnProps & DispatchProps & StateProps;
type State = OwnState;
class PushToken extends PureComponent<Props, State> {
    state: State = {
        displayModalForEditToken: false,
    };

    componentDidMount() {
        this.props.setTokenExistStatus(0);
    }

    setDisplayModalForEditToken(displayModal: boolean): void {
        this.setState({displayModalForEditToken: displayModal});
    }

    getSnackbarData(): SnackbarDataT {
        const {tokenExistStatus}: Props = this.props;

        const snackbarData: SnackbarDataT = {
            message: "Token isn't exist!",
            color: "error",
            display: tokenExistStatus !== 0,
        };

        if (tokenExistStatus === 1) {
            snackbarData.message = "Token exist!";
            snackbarData.color = "success";
        }

        return snackbarData;
    }

    copyToClipboard(text: string | undefined) {
        const textField = document.createElement("textarea");
        textField.innerText = text || "";
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();

        alert("Successfuly token copied!");
    }

    hideSnackbar(): void {
        const {setTokenExistStatus}: Props = this.props;

        setTokenExistStatus(0);
    }

    render() {
        const {token}: Props = this.props;
        const {displayModalForEditToken}: State = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} style={styles.wrapperButtonGroup}>
                    <KeyIcon />
                    <IconButton style={{marginLeft: 5}} onClick={this.copyToClipboard.bind(this, token)}>
                        <ContentCopyIcon />
                    </IconButton>
                    <IconButton onClick={this.setDisplayModalForEditToken.bind(this, true)}>
                        <EditIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="filled-multiline-flexible"
                        placeholder="Token"
                        multiline
                        minRows={4}
                        variant="filled"
                        style={styles.textInput}
                        value={token || ""}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} style={{marginTop: 10}}>
                    <Button
                        variant="contained"
                        onClick={this.props.verificationToken}
                        style={{float: "right", minWidth: 100, minHeight: 30}}
                    >
                        {this.props.loading ? <CircularProgress size={20} color="inherit" /> : "Test Token"}
                    </Button>
                </Grid>

                <Snackbar
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    open={this.getSnackbarData().display}
                    autoHideDuration={4000}
                    onClose={this.hideSnackbar.bind(this)}
                    message={this.getSnackbarData().message}
                />

                <ModalUpdateToken
                    currentToken={token}
                    displayModal={displayModalForEditToken}
                    setDisplayModal={this.setDisplayModalForEditToken.bind(this)}
                />
            </Grid>
        );
    }
}

const mapStateToProps = ({appNotifications}: RootState): StateProps => ({
    loading: selectloadingDataAboutToken(appNotifications),
    tokenExistStatus: selectTokenExistStatus(appNotifications),
});

const mapDispatchToProps = {
    verificationToken,
    setTokenExistStatus: appNotificationsActions.setTokenExistStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PushToken);
