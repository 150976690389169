import {CombinedState, combineReducers, Reducer} from "redux";
import appNotificationsReducer from "src/app-services/notifications/reducers";
import homeReducer from "src/feature/home-test/reducers";
import noitifcationsTestReducer from "src/feature/test-send-notifications-app/reducers";
import {RootState} from "~redux/reducers/interface";
import themeReducer from "~theme/reducers";

const rootReducer: Reducer<CombinedState<RootState>> = combineReducers({
    home: homeReducer,
    theme: themeReducer,
    notificationTest: noitifcationsTestReducer,
    appNotifications: appNotificationsReducer,
});

export default rootReducer;
