import {UserData} from "src/feature/home-test/interface";
import {
    APPROVE_USER_PERMISSIONS,
    DECLINE_USER_PERMISSIONS,
    SET_SELECTED_USER,
    UPDATE_ANY_FIELD_OF_USER,
    UPDATE_WHOLE_OBJECT_OF_USER,
} from "./constants";
import {
    ApproveUserPermissionsAction,
    DeclineUserPermissionsAction,
    SetSelectedUserAction,
    UpdateAnyFieldOfUserAction,
    UpdateAnyFieldOfUserT,
    UpdateWholeObjectOfUserAction,
} from "./interface";

export const setSelectedUser = (payload: UserData): SetSelectedUserAction => ({
    type: SET_SELECTED_USER,
    payload,
});

export const updateAnyFieldOfUser = (payload: UpdateAnyFieldOfUserT): UpdateAnyFieldOfUserAction => ({
    type: UPDATE_ANY_FIELD_OF_USER,
    payload,
});

export const updateWholeObjectOfUser = (payload: UserData): UpdateWholeObjectOfUserAction => ({
    type: UPDATE_WHOLE_OBJECT_OF_USER,
    payload,
});

export const approveUserPermissions = (payload: string): ApproveUserPermissionsAction => ({
    type: APPROVE_USER_PERMISSIONS,
    payload,
});

export const declineUserPermissions = (payload: string): DeclineUserPermissionsAction => ({
    type: DECLINE_USER_PERMISSIONS,
    payload,
});
