import {AppNotificationsState} from "src/app-services/notifications/interface";
import {HomeState} from "src/feature/home-test/interface";
import {NotificationsTestState} from "src/feature/test-send-notifications-app/interface";
import {ThemeState} from "~theme/interface";

export enum Slices {
    home = "home",
    theme = "theme",
    notificationsTest = "notificationsTest",
    appNotiifcations = "appNotiifcations",
}

export interface RootState {
    home: HomeState;
    theme: ThemeState;
    notificationTest: NotificationsTestState;
    appNotifications: AppNotificationsState;
}
